.mainMenuLogo {
  float: left;
  background-image: var(--logoUrl);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  max-width: 200px;
  width: 100%;
  height: 80px;
  }
  