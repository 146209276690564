.login .logo {
  color: #b9b9b9;
    font-size: 28px;
    font-weight: 700;
    left: 30px;
    position: absolute;
    top: 20px;
    z-index: 2;
}


.login .loginBackground {
  
  background: url(/public/7-text-editor-tools-that-will-improve-your-writing-v.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(1) blur(3px);
  

  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 1;
}

.warning {
  color: red;
}

.loginContainer {
  align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    z-index: 2;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
}

.loginButton {
  display: inline-flex;
  width: 150px;
  height: 35px;
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}


.login .loginContainer h1 {
  color: black;
  font-size: 31px;
  line-height: 54px;
  margin: 0px;
}


.login .loginContainer .SubHeadingContainer {
  padding-top: 30px;
  max-width: 680px;
  margin-right: 40px
}

.login .loginContainer .HeadingContainer {
  max-width: 600px;
}

.login .loginContainer .loginBoxContainer {
  background: #fff;
  border-radius: 10px;
  width: 600px;
  filter: drop-shadow(0px 0px 2px #c8c8c8);
}

.login .loginContainer .loginBoxContainer .textContainer {
  padding: 30px;
}