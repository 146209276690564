.mainLogItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 35px;
  border-bottom: 1px solid #dbdbdb;
  align-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

pre {
  white-space: pre-wrap; /* Allows wrapping */
  word-wrap: break-word;  /* Ensures long words break */
  overflow-wrap: break-word; /* Ensures content breaks */
}

.logItemRed {
  background-color: #f44336;
  width: 3px;
  height: 80%;
}

.logItemYellow {
  background-color: #ffeb3b;
  width: 3px;
  height: 80%;
} 

.logItemGreen {
  background-color: #4caf50;
  width: 3px;
  height: 80%;
}




.logItemDetailsRed {
  background-color: #f44336;
  width: 3px;
  height: 100%;
  position: absolute;
}

.logItemDetailsYellow {
  background-color: #ffeb3b;
  width: 3px;
  height: 100%;
  position: absolute;
} 

.logItemDetailsGreen {
  background-color: #4caf50;
  width: 3px;
  height: 100%;
  position: absolute;
}




.logItemIcon {
  font-size: 20px;
  color: #9e9e9e;
  cursor: pointer;
}

.logItemDate {
  color: #9e9e9e;
  min-width: 140px;
}
.logItemLogType {
  color: #9e9e9e;
} 
  
.logItemActivity {

  text-overflow: ellipsis;
  overflow: hidden; 
  max-width: calc(100% - 300px);
  white-space: nowrap;
}

.logItemDetails {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  position: relative;
  
}

.logItemDetailsItem {
 background-color: #ececec  ;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 10px;
}

.logItemDetailsItemLabel {
  min-width: 80px;
  margin-left: 45px;
  font-weight: bold;
}

.logItemDetailsItemData {
  width: calc(100% - 135px);
  overflow-wrap: break-word;
  word-wrap: break-word;
}