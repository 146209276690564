
.orderList .contentContainer {
  border: 0px solid var(--primary-color);
  padding: 20px;
  background: #fff;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.orderList .Heading {
  text-align: center;
  font: normal normal bold 35px / 46px Roboto;
  margin-bottom: 40px;
}
